import { createBrowserRouter } from 'react-router-dom'
import { UberEats } from './uber-eats'
import { SumniCloudPrinter } from './sumni-cloud-printer'
import { FaxPlus } from './fax-plus'

/**
 * Router for the top level of the application
 */
export const router = createBrowserRouter([
  {
    path: '/',
    element: <>Hello World 1</>,
  },
  {
    path: '/uber-eats',
    index: true,
    element: <UberEats />,
  },
  {
    path: 'sumni-cloud-printer',
    index: true,
    element: <SumniCloudPrinter />,
  },
  {
    path: 'fax-plus',
    index: true,
    element: <FaxPlus />,
  },
])
