import { FC, useState } from 'react'
import { ApplicationFrame } from '../../components/ApplicationFrame'
import blackPrinterImage from '../../assets/sumni-cloud-printer/printers-black.jpg'
import {
  useClientApiCall,
  useClientApiMutation,
} from '../../hooks/useClientApiCall'
import { useSearchParams } from 'react-router-dom'
import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Badge,
  Box,
  Button,
  Typography,
} from '@mui/material'
import { ApplicationLoadingScreen } from '../../components/ApplicationLoadingScreen'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { AddPrinterDialog } from './AddPrinterDialog'

/**
 * Uber Eats Application
 */
export const SumniCloudPrinter: FC = () => {
  const [params] = useSearchParams()
  const [addPrinterDialogOpen, setAddPrinterDialogOpen] = useState(false)
  const session = params.get('session')!
  const connection = useClientApiCall<any>(session, 'connection')

  /**
   * While we are loading the connection, we will show a loading spinner
   */
  if (connection.isLoading) {
    return <ApplicationLoadingScreen />
  }

  /**
   *
   */
  return (
    <ApplicationFrame heroImage={blackPrinterImage}>
      {addPrinterDialogOpen && (
        <AddPrinterDialog
          connection={connection}
          open={addPrinterDialogOpen}
          onClose={() => setAddPrinterDialogOpen(false)}
        />
      )}
      {/* Centralised Title with padding */}
      <Typography variant="h4" align="center" sx={{ p: 2 }}>
        Sumni Cloud Printer
      </Typography>
      {/* Button on the right to add printer*/}
      <Box pb={2}>
        <Button onClick={() => setAddPrinterDialogOpen(true)}>
          Add Printer
        </Button>
      </Box>

      <ConnectedPrinters />
    </ApplicationFrame>
  )
}

export const ConnectedPrinters: FC = () => {
  const [params] = useSearchParams()
  const session = params.get('session')!
  const connection = useClientApiCall<any>(session, 'connection')
  const testPrinter = useClientApiMutation(session, 'test-printer')
  const updatePrinter = useClientApiMutation(session, 'update-printer')
  const removePrinter = useClientApiMutation(session, 'remove-printer')

  const updatePrinterHandler = (options: { name: string; sn: string }) => {
    updatePrinter.mutate(options, {
      onSettled: () => connection.refetch(),
    })
  }

  /**
   * Remove Printer Handler
   */
  const removePrinterHandler = (options: { sn: string }) => {
    removePrinter.mutate(options, {
      onSettled: () => connection.refetch(),
    })
  }

  return (
    <Box>
      <Typography variant="h6">Connected Printers</Typography>
      {connection.data?.printers?.map(
        (printer: {
          name: string
          sn: string
          online: boolean
          status: string
        }) => (
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              {/* Small green / red dot  */}
              <Typography variant="body1">
                <Badge
                  variant="dot"
                  color={printer.online ? 'success' : 'warning'}
                  sx={{ mr: 2 }}
                />
                {printer.name}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              {/* Serial Number */}
              <Typography variant="body2">
                Serial Number: {printer.sn}
              </Typography>
              {/* Status */}
              <Typography variant="body2">Status: {printer.status}</Typography>
            </AccordionDetails>
            <AccordionActions>
              <Button
                onClick={() => removePrinterHandler({ sn: printer.sn })}
                disabled={removePrinter.isLoading}
              >
                Delete
              </Button>
              <Button
                onClick={() => testPrinter.mutate({ sn: printer.sn })}
                disabled={testPrinter.isLoading}
              >
                Test Print
              </Button>
            </AccordionActions>
          </Accordion>
        )
      )}
    </Box>
  )
}
